import {React} from 'react';
import "../styles.css";

import Committees from './Committees';


const StudentLife = () => {
    return (
        <main>
            <div className='StudentLife'>
                <Committees />
            </div>
        </main>
        
    );
};

export default StudentLife;
