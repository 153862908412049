import {React} from "react";
import './styles.css';
 
const Abroad = () => {
    return (
        <main>
            <p>Coming soon (?)</p>
        </main>
    );
};
 
export default Abroad;
