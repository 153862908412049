import {React} from "react";
 
const Contact = () => {
    return (
        <main>
            <h1>CS (Data) Chapter</h1>
            <p>
                isc@datasektionen.se
            </p>
            <h1>Media Chapter</h1>
            <p>
                international@medieteknik.com
            </p>
        </main>
    );
};
 
export default Contact;
